@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';
@import '_tierra_de_titanes.scss';
@import '_fonts.scss';

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error


.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

