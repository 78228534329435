$lagunaRosa: #FF0077;
$lagunaRosaSecundario: #E42581;
$lagunaMorado: #BC397A;
$lagunaGreen: #588B27;
$lagunaBlue: #1F97B7;
$lagunaOrange: #FF953E;
$lagunaLightGreen: #87BF4F;
$lagunaMustard: #FFC601;
$greenA: #249300;
$greenB: #0A7F58;
$greenC: #79B000;
$aqua: #0D677C;
$aquaLight: #1F97B7;

body {
    font-family: 'Aller Regular', sans-serif;
    color: black;
}

h1,
h2,
h3,
h4 {
    font-family: "Cocogoose", sans-serif;
    color: $white;
}


.lagunaGreen {
    color: $lagunaGreen;
}

.hero-main {
    height: 30em;
    background-image: url('../images/hero.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.btn-primary {
    border-radius: 25px;
    font-family: "Cocogoose", sans-serif;
    color: white;
    background-color: $lagunaRosa;
    border-color: $lagunaRosa;

    &:hover {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: black;
        background-color: $lagunaMorado;
        border-color: $lagunaRosa;
    }

    &:focus {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: black;
        background-color: $lagunaMorado;
        border-color: $lagunaRosa;
    }

    &:active {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: $white;
        background-color: $lagunaRosa !important;
        border-color: $lagunaRosa;
    }

    a {
        color: white;
        text-decoration: none;
    }
}

.btn-secondary {
    border-radius: 25px;
    font-family: "Cocogoose", sans-serif;
    color: white;
    background-color: $aquaLight;
    border-color: $aquaLight;

    &:hover {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: white;
        background-color: $aqua;
        border-color: $aqua;
    }

    &:focus {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: white;
        background-color: $aquaLight;
        border-color: $aquaLight;
    }

    &:active {
        border-radius: 25px;
        font-family: "Cocogoose", sans-serif;
        color: $white;
        background-color: $aquaLight !important;
        border-color: $aquaLight;
    }

    a {
        color: white;
        text-decoration: none;
    }
}

.play-button {
    border-radius: 50px;

    &:hover {
        border-radius: 50px;
        font-family: "Cocogoose", sans-serif;
        color: white;
        background-color: $lagunaMorado;
        border-color: $lagunaRosa;
    }

    &:focus {
        border-radius: 50px;
        font-family: "Cocogoose", sans-serif;
        color: white;
        background-color: $lagunaMorado;
        border-color: $lagunaRosa;
    }

    &:active {
        border-radius: 50px;
        font-family: "Cocogoose", sans-serif;
        color: black;
        background-color: $dark;
        border-color: $lagunaRosa;
    }

    a {
        color: white;
        text-decoration: none;
    }
}

.espaciado {
    margin-top: 30px;
    margin-bottom: 3    0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.barra {
    padding-top: 20px;
    width: 100%;
    height: 20px;

    &.azul {
        background-color: $lagunaBlue;
    }

    &.rosa {
        background-color: $lagunaRosa;
    }

    &.naranja {
        background-color: $lagunaOrange;
    }

    &.verdeClaro {
        background-color: $lagunaLightGreen;
    }

    &.mostaza {
        background-color: $lagunaMustard;
    }
}

.contenido {
    padding-top: 20px;
    width: 100%;
    height: 12em;
    color: white;
    text-align: left;

    & li {
        padding-top: 1em;
    }

    &.azul {
        background-color: $lagunaBlue;
    }

    &.rosa {
        background-color: $lagunaRosa;
    }

    &.naranja {
        background-color: $lagunaOrange;
    }

    &.verdeClaro {
        background-color: $lagunaLightGreen;
    }

    &.mostaza {
        background-color: $lagunaMustard;
    }
}

.checkpoint {
    font-family: "Cocogoose", "sans-serif";
    font-weight: bold;

    a {

        text-decoration: none;
    }

    &.azul {
        color: $lagunaBlue;

        & a {
            color: $lagunaBlue;
        }
    }

    &.rosa {
        color: $lagunaRosa;

        & a {
            color: $lagunaRosa;
        }
    }

    &.naranja {
        color: $lagunaOrange;

        & a {
            color: $lagunaOrange;
        }
    }

    &.verdeClaro {
        color: $lagunaLightGreen;

        & a {
            color: $lagunaLightGreen;
        }
    }

    &.mostaza {
        color: $lagunaMustard;

        & a {
            color: $lagunaMustard;
        }
    }

    &.aqua {
        color: $aqua;

        & a {
            color: $aqua;
        }
    }

    &.aquaLight {
        color: $aquaLight;

        & a {
            color: $aquaLight;
        }
    }
    &.lagunaRosaSecundario {
        color: $lagunaRosaSecundario;

        & a {
            color: $lagunaRosaSecundario;
        }
    }

    

}

area {
    outline: green solid 3px;
    cursor: pointer;
}

map {
    display: block;
    position: relative;
}

.lagunaRosa {
    background-color: $lagunaRosa;
}


.lagunaAquaLight {
    background-color: $aquaLight;
}

.greenA {
    background-color: $greenA;
}

.greenC {
    background-color: $greenC;
}

.divider {
    border-left: 1px solid #505050;
    height: 3em;
}


@media (max-width: 576px) {
    .top-padding-md {
        padding-top: 10px;
        /* Add desired top padding for small-sized devices */
    }

    .top-margin-md {
        margin-top: 10px;
        /* Add desired top padding for small-sized devices */
    }

    .hero-main {
        height: 30em;
        margin-top: -3em;
    }

}

@media (min-width: 577px) {
    .top-padding-md {
        padding-top: 10px;
        /* Add desired top padding for small-sized devices */
    }

    .top-margin-md {
        margin-top: 10px;
        /* Add desired top padding for small-sized devices */
    }

    .hero-main {
        height: 30em;
        margin-top: -3em;
    }

}


@media (min-width: 768px) {
    .top-padding-md {
        padding-top: 20px;
        /* Add desired top padding for medium-sized devices */
    }

    .top-margin-md {
        margin-top: 20px;
        /* Add desired top margin for medium-sized devices */
    }

    .hero-main {
        height: 30em;
        margin-top: -3em;
    }

}

@media (min-width: 992px) {
    .top-padding-md {
        padding-top: 40px;
        /* Add desired top padding for large-sized devices */
    }

    .hero-main {
        height: 35em;
        margin-top: -3em;
    }

    .top-margin-md {
        margin-top: 40px;
        /* Add desired top margin for large-sized devices */
    }

    .top-negative-margin {
        margin-top: -10px;
    }
}