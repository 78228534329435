@font-face {
    font-family: 'Cocogoose';
    src: url('../fonts/Cocogoose_trial.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
    font-family: 'Aller Bold';
    src: url('../fonts/Aller_Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Aller Regular';
    src: url('../fonts/Aller_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/GOTHICB.TTF') format('truetype');
    font-weight: bold;
    font-style:normal;
}
  
